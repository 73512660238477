<template>
  <div class="conversation-entry" :class="{ 'sent-by-me': loggedUser.id === conversationEntry.from.id }">
      <img class="thumbnail" :src="conversationEntry.from.thumbnail"/>
    <div class="message-bubble">
      {{ conversationEntry.message }}
    </div>
  </div>
</template>

<script setup>
  import { computed, defineModel } from 'vue';
import { useStore } from 'vuex';

  const store = useStore();
  const conversationEntry = defineModel();
  const loggedUser = computed(() => store.getters.currentUser);

</script>

<style lang="css" scoped>
.conversation-entry {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  max-width: 60%;
}

.conversation-entry:first-of-type {
  margin-top: auto;
}

.conversation-entry.sent-by-me {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.thumbnail {
  margin: 0 12px 0 0;
  border-radius: 50%;
  padding: 8px;
  height: 48px;
  width: 48px;
  align-self: flex-end;
}

.message-bubble {
  white-space: pre-wrap;
  padding: 8px;
  border-radius: 8px;
  background-color: ghostwhite;
  text-align: left;
  max-width: 100%;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.sent-by-me {
  .thumbnail {
    margin: 0 0 0 12px;
  }

  .message-bubble {
    background-color: aliceblue;
  }
}

</style>