<template>
  <div class="chat-window">
    <div v-if="isError" class="error-container">
      {{ isError }}
    </div>
    <template v-else>
      <conversation-area v-model="conversation"></conversation-area>
      <compose-section @submit="onMessageSubmitted"></compose-section>
    </template>
  </div>
</template>

<script>
import ComposeSection from './ComposeSection.vue';
import ConversationArea from './ConversationArea.vue';

import { useStore } from 'vuex';
import { computed, defineComponent, ref } from 'vue';
export default defineComponent({
  components: {
    ComposeSection,
    ConversationArea
  },
  async setup() {
    const store = useStore();
    const isError = ref(false);
    const currentUser = computed(() => store.getters.currentUser);
    const conversation = computed(() => store.getters.conversation);

    const onMessageSubmitted = (message) => {
      store.dispatch('postMessage', message)
    }

    const doRefresh = async () => {
      isError.value = false;
      if (!currentUser.value) {
        try {
          await store.dispatch('loginUser');
          await store.dispatch('fetchConversation', currentUser.value.id);
        } catch(e) {
          console.error(e)
          isError.value = e;
        }
      }
    }

    await doRefresh();

    return {
      isError,
      currentUser,
      conversation,
      onMessageSubmitted,
      doRefresh,
    }
  }
})
</script>

<style lang="css" scoped>
  .chat-window {
    width: 100%;
    height: 100%;
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .error-container {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: auto;
    align-content: center;
  }
</style>