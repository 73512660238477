import { createStore } from "vuex";
import * as mockServer from "./mockapi";

export default createStore({
  state: {
    currentUser: null,
    conversation: [],
    loading: {},
  },
  getters: {
    currentUser({ currentUser }) {
      return currentUser;
    },
    conversation({ conversation }) {
      return conversation;
    },
    isLoading(state) {
      return (action) => !!state.loading[action];
    }
  },
  mutations: {
    setCurrentUser(state, loggedInUser) {
      state.currentUser = loggedInUser;
    },
    setConversation(state, conversation) {
      state.conversation = conversation;
    },
    postMessageToConversation(state, message) {
      state.conversation.push(message);
    },
    revertMessageFromConversation(state, message) {
      state.conversation = state.conversation.filter(({ id }) => id !== message.id);
    },
    activateLoading(state, action) {
      state.loading[action] = true;
    },
    deactivateLoading(state, action) {
      state.loading[action] = false;
    }
  },
  actions: {
    async loginUser({ commit }) {
      commit('activateLoading', 'loginUser');
      try {
        const loggedInUser = await doRequest('currentUser')
        commit('setCurrentUser', loggedInUser)
      } finally {
        commit('deactivateLoading', 'loginUser');
      }
    },
    async fetchConversation({ commit }, id) {
      commit('activateLoading', 'fetchConversation');
      try {
        const currentConversation = await doRequest('conversation', id);
        commit('setConversation', currentConversation);
      } finally {
        commit('deactivateLoading', 'fetchConversation');
      }
    },
    async postMessage({ commit, state }, message) {
      commit('activateLoading', 'postMessage');
      const messageData = {
        id: state.conversation.length + 1,
        from: state.currentUser,
        date: getCurrentDate(),
        message
      };
      try {
        commit('postMessageToConversation', messageData);
        await doRequest('message', messageData);
      } catch(e) {
        commit('revertMessageFromConversation', messageData);
      } finally {
        commit('deactivateLoading', 'postMessage');
      }
    },
  }
});

// Mock the server call to fetch the data
// Each request takes 0.5 seconds to complete
// There is a 10% chance for the request to fail
const doRequest = (api) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (Math.random() > 0.9) {
        reject("An error has occured. Try again");
      } else {
        let data = null;
        try {
          const response = mockServer[api];
          if (response) data = JSON.parse(response);
        } catch(e) {
          console.error("Error parsing json", e);
        } finally {
          resolve(data)
        }
      }
    }, 500);
  });
}

function getCurrentDate() {
  const currentDate = new Date(Date.now());
  var d = (currentDate + '').split(' ');

  return `${d[3]}-${currentDate.getMonth() + 1}-${d[2]} ${d[4]}`;
}