<template>
  <div class="compose-container">
    <textarea
      ref="messageElement"
      class="message-content"
      placeholder="Type your message here..."
      @input="adjustHeight"
      @keypress.enter="handleEnter"
      v-model="messageContent"></textarea>
    <button class="send-button" @click="handleClick">
      <font-awesome-icon class="send-icon" size="xl" icon="fa-paper-plane" />
    </button>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineModel } from 'vue';

const messageContent = defineModel();
const messageElement = ref(null);

const emit = defineEmits(['submit']);

function adjustHeight() {
  // Set the height of textarea element based on scrollheight and lineheight
  // The maximum height should be 3x lineheight, and minimum 1x lineheight
  // Enable scroll when scrollheight is more than the allowed maximum.
  const textarea = messageElement.value;
  textarea.style.height = getComputedStyle(textarea).minHeight;

  const padding = parseFloat(getComputedStyle(textarea).paddingTop)
    + parseFloat(getComputedStyle(textarea).paddingBottom);

  const scrollHeight = textarea.scrollHeight - padding;
  const lineHeight = getComputedStyle(textarea).lineHeight;
  const newHeight = Math.min(scrollHeight, 3 * parseFloat(lineHeight));
  
  textarea.style.height = newHeight + 'px';
  textarea.style.overflowY = scrollHeight > newHeight ? 'auto' : 'hidden';
}

function handleEnter(evt) {
  // Do not send message on SHIFT+ENTER to allow newline insertion
  // and block inserting newline on ENTER
  if (!evt.shiftKey) {
    evt.preventDefault();
    sendMessage()
    // recalculate the textarea height
    const textarea = messageElement.value
    textarea.value = '';
    adjustHeight();
  }
}

function handleClick() {
  sendMessage();
  // recalculate the textarea height
  const textarea = messageElement.value
  textarea.value = '';
  adjustHeight();
}

function sendMessage() {
  if (messageContent.value) {
    emit('submit', messageContent.value);
    messageContent.value = '';
  }
}
</script>

<style lang="css" scoped>
.compose-container {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: flex-end;
}

.message-content {
  width: 100%;
  border-radius: 8px;
  border: 1px solid gray;
  align-self: center;
  resize: none;
  overflow: hidden;
  height: 1.5em;
  line-height: 1.5;
  max-height: 4.5em;
  min-height: 1.5em;
  padding: 5px;
  align-content: center;
}

.message-content:focus-visible {
  outline-color: rgb(182, 213, 239);
  border-color: rgb(182, 213, 239);
}

.send-button {
  border: none;
  background: none;
  margin-left: 12px;
  width: 60px;
  align-self: center;
  cursor: pointer;
  text-align: start;
}
</style>