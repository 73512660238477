<template>
  <div class="conversation-area" ref="conversationArea">
    <message-entry v-for="(entry, index) in conversationEntries"
      v-bind:key="entry.id"
      v-model="conversationEntries[index]">
    </message-entry>
    <div ref="listEnd"></div>
  </div>
</template>

<script setup>
import MessageEntry from './MessageEntry.vue';
import { defineModel, ref, watch, nextTick, onMounted } from 'vue';

const conversationEntries = defineModel();
const listEnd = ref(null);

// Scroll new messages into view
watch(() => conversationEntries.value, () => {
  scrollIntoView();
}, { deep: true });

onMounted(() => {
  scrollIntoView();
});

function scrollIntoView() {
  nextTick(() => {
    listEnd.value.scrollIntoView({ behavior: 'smooth' })
  })
}
</script>

<style lang="css" scoped>
.conversation-area {
  padding: 1rem;
  height: inherit;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>